<template>
  <div id="addDiscussion">
    <v-card>
      <v-form ref="form" @submit.prevent="add()">
        <v-card-title>Yeni Tartışma</v-card-title>
        <v-divider />
        <v-card-text>
          <v-text-field
            id="title"
            v-model="form.title"
            label="Başlık"
            name="title"
            type="text"
            required
            :rules="titleRules"
          />
          <div class="mt-5">
            <label class="v-label theme--light d-block mb-2">Açıklama</label>
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="form.text"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">İptal Et</v-btn>
          <v-btn color="primary" type="submit" text :disabled="submitDisabled"
            >Gönder</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/tr";

export default {
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  components: {
    ckeditor: CKEditor.component
  },
  data: () => ({
    form: {
      title: "",
      text: ""
    },
    submitDisabled: false,
    editor: ClassicEditor,
    editorConfig: {
      language: "tr",
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "undo",
        "redo"
      ]
    }
  }),
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
    titleRules() {
      return [v => !!v || "Lütfen başlık yazınız"];
    }
  },
  methods: {
    close() {
      this.reset();
      this.$emit("close");
    },
    refresh() {
      this.$emit("refresh");
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.form.text = "";
      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async add() {
      if (this.validate()) {
        this.submitDisabled = true;
        this.$notify({
          text: "Tartışma ekleniyor. Lütfen bekleyin..."
        });

        try {
          const now = new Date(Date.now());
          const newDiscus = {
            courseId: this.course.id,
            userId: this.user.id,
            title: this.form.title,
            text: this.form.text,
            posts: 0,
            creationTime: now,
            updateTime: null
          };

          // Add discuss to database
          await firebase
            .firestore()
            .collection("discussions")
            .add(newDiscus);

          setTimeout(() => {
            this.refresh();
            this.close();
          }, 1000);

          this.$notify({
            type: "success",
            text: "Tartışma başarıyla eklendi."
          });
        } catch (err) {
          this.$notify({
            type: "error",
            text: "Tartışma eklenemedi"
          });

          console.log(err);
        } finally {
          this.submitDisabled = false;
        }
      }
    }
  }
};
</script>

<style lang="scss">
#addDiscussion {
  .ck-content {
    min-height: 100px;
  }
}
</style>
